import styled from 'styled-components';

import { PropTypes } from '@/utils/propTypes';

const ContentText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #949494;
  white-space: break-spaces;
`;

const Content = ({ children }) => {
  return (
    <ContentText dangerouslySetInnerHTML={{ __html: children }}></ContentText>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
