import { Button } from 'antd';

import svgArrowDown from '@/assets/icons/svg-arrow-down.svg';
import SVG from '@/patterns/SVG';
import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

const StyledButton = styled(Button)`
  display: flex;
  position: relative;
  padding: 0;
  border: none;
  height: auto;
  background: #f5f5f5;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #111111;
  box-shadow: none;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    border: none;
    box-shadow: none;
    color: #111111;
    background: #f5f5f5;
  }
`;

const TextBox = styled.span`
  width: 66px;
  padding: 12px;
  padding-right: 30px;
  display: block;
  border-right: 1px solid #dfdfdf;
`;

const IconBox = styled.div`
  padding: 13px;
`;

const DropdownLikeButton = ({ onClick, text }) => {
  return (
    <StyledButton onClick={onClick}>
      <TextBox>{text}</TextBox>
      <IconBox>
        <SVG src={svgArrowDown} />
      </IconBox>
    </StyledButton>
  );
};

DropdownLikeButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default DropdownLikeButton;
