import { useCallback } from 'react';

import { message as antdMessage } from 'antd';

const useInfoSnackbar = () => {
  const show = useCallback(
    (/** @type {string | import('react').ReactNode} */ infoMessage) => {
      antdMessage.warn({
        content: infoMessage,
        className: 'styled-info-snack-bar-alert',
        duration: 3,
      });
    },
    []
  );
  const close = useCallback((key) => {
    antdMessage.destroy(key);
  }, []);
  return { show, close };
};

export default useInfoSnackbar;
