/**
 * 日期欄位
 */
import { useMemo } from 'react';

import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

import DropdownLikeButton from './DropdownLikeButton';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3.67px;
`;

const DateInput = (
  /** @type {{onClick: () => void, date: Date}} */ { onClick, date }
) => {
  const [year, month, day] = useMemo(
    () =>
      date ? date.toISOString().split('T')[0]?.split('-') : ['-', '-', '-'],
    [date]
  );
  const twYear = useMemo(
    () => (year === '-' ? '-' : Number(year) - 1911),
    [year]
  );

  return (
    <Wrapper>
      <DropdownLikeButton onClick={onClick} text={twYear.toString()} />
      <DropdownLikeButton onClick={onClick} text={month.toString()} />
      <DropdownLikeButton onClick={onClick} text={day.toString()} />
    </Wrapper>
  );
};

DateInput.propTypes = {
  onClick: PropTypes.func,
  date: PropTypes.instanceOf(Date),
};

export default DateInput;
