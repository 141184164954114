/**
 * LM-LIFF-02 綁定流程-個資填寫 https://lmliff.cht.com.tw/sign-up
 */
import { useMemo, useState } from 'react';
import { navigate } from 'gatsby';

import { Checkbox, Radio } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { useGetUserInfo } from '@/apis/hooks';
import pngMainBanner from '@/assets/images/img-banner@3x.png';
import CircleStepper from '@/components/CircleStepper';
import DateInput from '@/components/DateInput';
import PrimaryInput from '@/components/PrimaryInput';
import ScrollingTextView from '@/components/ScrollingTextView';
import SlideInDatePicker from '@/components/SlideInDatePicker';
import StandardButton from '@/components/StandardButton';
import useInfoSnackbar from '@/hooks/useInfoSnackbar';
import ProgressiveImage from '@/patterns/ProgressiveImage';
import { styled } from '@/utils/styledComponents';

const ContentWrapper = styled.div`
  padding: 40px 16px 12px 16px;
`;
const StepperWrapper = styled.div`
  padding-bottom: 16px;
`;
const Title = styled.div`
  padding-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 34px;
  color: #111111;
`;

const InputBox = styled.div`
  padding-bottom: 1rem;
`;
const InputLabel = styled.label`
  display: inline-block;
  padding-bottom: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  color: #555555;
  &.sm-label {
    font-size: 14px;
    line-height: 20px;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
`;
const StyledRadio = styled(Radio)`
  width: 100%;
  align-items: center;
  .ant-radio {
    font-size: 0;
    & + span {
      font-size: 15px;
      line-height: 140%;
      letter-spacing: -0.25px;
      color: #555555;
    }
  }
  .ant-radio-inner {
    width: 24px;
    height: 24px;
    border: 1px solid #dfdfdf;
    &:after {
      width: 28px;
      height: 28px;
      margin-top: -14px;
      margin-left: -14px;
    }
  }
`;

const CheckboxWrapper = styled.div`
  padding: 1rem 0 2.5rem;
`;
const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  font-size: 0.875rem;
  .ant-checkbox-checked:after {
    border-radius: 100%;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    &:after {
      border-width: 1px;
      left: 28%;
    }
  }
  .ant-checkbox + span {
    font-weight: 400;
    line-height: 20px;
    color: #555555;
    .link-type {
      color: #4270ed;
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
`;
const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
`;

const SignUpForm = () => {
  const { userInfo } = useGetUserInfo();

  const agreementTitle = useMemo(
    () =>
      _.get(
        userInfo,
        'agreement.title',
        'LINE MOBILE專屬服務身份驗證及服務條款'
      ),
    [userInfo]
  );

  const agreementContent = useMemo(
    () =>
      _.get(
        userInfo,
        'agreement.content',
        `感謝您加入及綁定本公司LINE官方帳號(以下或稱為「本服務」)好友，本公司重視您的隱私與個人資料保護，並尊重您的個人資料控制權。以下說明本公司如何蒐集、處理、利用您的個人資料，以及您有哪些權利可以行使。\n\n請留意，如您不是申請加入及綁定本服務之人(非契約當事人)但使用本服務時（由他人申請加入及綁定但供您使用）`
      ),
    [userInfo]
  );

  const infoSnackbar = useInfoSnackbar();
  const [userName, setUserName] = useState('');
  const [isInvalidUserName, setIsInvalidUserName] = useState(true);
  const updateIsInvalidUserName = (value) => {
    setIsInvalidUserName(value);
  };
  const [mobile, setMobile] = useState('');
  const [isInvalidMobile, setIsInvalidMobile] = useState(true);
  const updateIsInvalidMobile = (value) => {
    setIsInvalidMobile(value);
  };
  const [birthday, setBirthday] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const showDatePicker = () => {
    setIsDatePickerOpen(true);
  };
  const handleDatePick = (date) => {
    setBirthday(date.toDate());
  };
  const [gender, setGender] = useState(null);
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };
  const [isPrivacyAgree, setIsPrivacyAgree] = useState(false);
  const handlePrivacyChange = (e) => {
    setIsPrivacyAgree(e.target.checked);
  };
  const [isPromotionAgree, setIsPromotionAgree] = useState(true);
  const handlePromotionChange = (e) => {
    setIsPromotionAgree(e.target.value);
  };

  const handleRequiredValue = (value, nullMessage) => {
    infoSnackbar.close();
    if (!value) {
      infoSnackbar.show(nullMessage);
    }
  };
  /**
   * 表單送出
   * 檢核欄位
   * 前往下一頁
   */
  const onFormSubmit = () => {
    infoSnackbar.close();
    const errorMessage = [];
    if (!userName)
      errorMessage.push(<div key="userName">尚未輸入真實姓名</div>);
    else if (isInvalidUserName)
      errorMessage.push(<div key="userName">姓名格式錯誤</div>);
    if (!mobile) errorMessage.push(<div key="mobile">尚未輸入手機號碼</div>);
    else if (isInvalidMobile)
      errorMessage.push(<div key="mobile">手機門號格式錯誤</div>);
    if (!birthday) errorMessage.push(<div key="birthday">尚未選擇生日</div>);
    if (!gender) errorMessage.push(<div key="gender">尚未選擇性別</div>);

    if (errorMessage.length) infoSnackbar.show(<div>{errorMessage}</div>);
    else {
      const state = {
        name: userName,
        mobile: mobile.replace(/-|\+/g, ''),
        birthday: moment(birthday).format('YYYY-MM-DD'),
        gender: gender,
        privacy_aggree: isPrivacyAgree,
        promotion_aggree: isPromotionAgree,
      };
      navigate('/sign-up/validate', { state });
    }
  };
  return (
    <form>
      <InputBox>
        <InputLabel>真實姓名</InputLabel>
        <PrimaryInput
          id="input-name"
          allowClear
          validationType="name"
          onChange={(value) => setUserName(value)}
          onValidate={(result) => updateIsInvalidUserName(result)}
          onBlur={() => handleRequiredValue(userName, '尚未輸入真實姓名')}
        />
      </InputBox>
      <InputBox>
        <InputLabel>手機號碼</InputLabel>
        <PrimaryInput
          id="input-phone-number"
          allowClear
          useDash
          validationType="mobile"
          onChange={(value) => setMobile(value)}
          onValidate={(result) => updateIsInvalidMobile(result)}
          onBlur={() => handleRequiredValue(mobile, '尚未輸入手機號碼')}
        />
      </InputBox>
      <InputBox style={{ paddingBottom: '2.5rem' }}>
        <InputLabel>生日</InputLabel>
        <DateInput onClick={showDatePicker} date={birthday} />
      </InputBox>
      <InputBox style={{ paddingBottom: '2.5rem' }}>
        <InputLabel style={{ paddingBottom: '1.75rem' }}>性別</InputLabel>
        <StyledRadioGroup onChange={handleGenderChange} value={gender}>
          <StyledRadio value={'M'}>男性</StyledRadio>
          <StyledRadio value={'F'}>女性</StyledRadio>
        </StyledRadioGroup>
      </InputBox>
      <ScrollingTextView title={agreementTitle} content={agreementContent} />
      <CheckboxWrapper>
        <StyledCheckbox checked={isPrivacyAgree} onChange={handlePrivacyChange}>
          我已閱讀並同意LINE MOBILE 個人資料蒐集。
        </StyledCheckbox>
      </CheckboxWrapper>

      <InputBox style={{ paddingBottom: '5.625rem' }}>
        <InputLabel style={{ paddingBottom: '1rem' }} className=" sm-label">
          您是否同意我們向您寄送第三人(關係企業或企業客戶)的商品/服務資訊，若不同意並不影響使用我們的服務）
        </InputLabel>
        <StyledRadioGroup
          onChange={handlePromotionChange}
          value={isPromotionAgree}
        >
          <StyledRadio value={true}>同意</StyledRadio>
          <StyledRadio value={false}>不同意</StyledRadio>
        </StyledRadioGroup>
      </InputBox>
      <Footer>
        <StandardButton
          type="primary"
          disabled={!isPrivacyAgree || !userName || !mobile}
          onClick={onFormSubmit}
        >
          下一步
        </StandardButton>
      </Footer>
      <SlideInDatePicker
        drawerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
        onConfirm={handleDatePick}
      />
    </form>
  );
};

const SignUpPage = () => {
  return (
    <div>
      <ProgressiveImage src={pngMainBanner} width="100%" />
      <ContentWrapper>
        <StepperWrapper>
          <CircleStepper totalSteps={2} currentStep={1} />
        </StepperWrapper>
        <Title>請填寫以下資訊</Title>
        <SignUpForm />
      </ContentWrapper>
    </div>
  );
};

export default SignUpPage;
