import styled from 'styled-components';

import { PropTypes } from '@/utils/propTypes';

const TitleText = styled.div`
  color: #303030;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const Title = ({ children }) => {
  return <TitleText>{children}</TitleText>;
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Title;
