/**
 * 日期選擇器
 */
import { useEffect, useState } from 'react';

import { Calendar, DatePicker, Drawer } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import svgChevronRight from '@/assets/icons/svg-chevron-right.svg';
import StandardButton from '@/components/StandardButton/index';
import SVG from '@/patterns/SVG';
import { PropTypes } from '@/utils/propTypes';
/**
 * 2023-04-16
 * iPad LIFF 看不到月份選擇器
 * iPad w540xh576
 * StyledDrawer h466 -> 380
 * isCompactView: 適用開啟LIFF頁面於高度600以下的裝置
 */
const isCompactView = () => {
  return typeof window !== 'undefined' && window.innerHeight <= 600;
};

const StyledDrawer = styled(Drawer)`
  .ant-picker-panel-container {
    box-shadow: none;
  }
  .ant-drawer-content {
    border-radius: 12px 12px 0 0;
  }
  .ant-drawer-body {
    padding: 24px 16px 12px;
  }
  ${isCompactView() &&
  `
    .ant-drawer-body {
      padding: 12px 16px 12px;
    }
  `}
`;
const StyledCalendar = styled(Calendar)`
  padding-top: 1rem;
  padding-bottom: 0.75rem;
  .ant-picker-panel {
    border-top: none !important;
  }
  .ant-picker-body {
    padding: 20px 0 0 !important;
  }
  .ant-picker-content {
    thead {
      height: 26px;
      th {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: -0.09px;
        color: rgba(60, 60, 67, 0.3);
      }
    }
  }
  .ant-picker-cell {
    padding: 0;
    &:not(.ant-picker-cell-in-view) {
      visibility: hidden;
    }
  }

  .ant-picker-cell-inner {
    width: 44px;
    height: 44px !important;
    border-radius: 100px !important;
    line-height: 44px !important;
    font-size: 17px;
  }
  .ant-picker-cell-today .ant-picker-cell-inner:before {
    border: none !important;
  }
  /**
   * 這段 isCompactView() == true 才需要
   */
  ${isCompactView() &&
  `
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
    .ant-picker-content {
      tbody {
        height: 22px;
        td {
          font-style: normal;
          font-size: 13px;
          letter-spacing: -0.09px;
          height: 22px;
          line-height: 22px;
        }
      }
    }
    .ant-picker-body {
      scroll-padding-top: 8px !important;
    }
    .ant-picker-cell {
      width: 22px;
      height: 22px !important;
      line-height: initial;
      font-size: 17px;
    }
    .ant-picker-cell-inner {
      width: 22px;
      height: 22px !important;
      border-radius: 100px !important;
      line-height: 22px !important;
      font-size: 17px;
      /* display: flex;
      align-items: center; */
    }
    .ant-picker-cell-disabled::before {
      background-color: transparent !important;
    }
  `}
`;
const calendarLocale = {
  lang: {
    locale: 'en',
    dayFormat: moment.updateLocale('en', {
      weekdaysMin: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    }),
  },
};
const IconChevronRight = styled(SVG)`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.25rem;
`;
const MonthButton = styled.div`
  display: flex;
  align-items: center;
  .momentValue {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.44px;
  }
`;
const HeaderPicker = ({ date, onChange }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const selectedMonthYear = date.format('MMM, YYYY');
  const onMonthYearChange = (newMonthYear) => {
    const now = date
      .clone()
      .year(newMonthYear.year())
      .month(newMonthYear.month());
    onChange(now);
  };
  useEffect(() => {
    if (isPickerOpen) {
      document.body.addEventListener('touchmove', preventTouchMove);
    } else {
      document.body.removeEventListener('touchmove', preventTouchMove);
    }
  }, [isPickerOpen]);
  return (
    <div style={{ fontSize: 0 }}>
      <DatePicker
        picker={'month'}
        onChange={onMonthYearChange}
        bordered={false}
        allowClear={false}
        open={isPickerOpen}
        onOpenChange={setIsPickerOpen}
        value={date}
        style={{
          visibility: 'hidden',
          height: '0px',
        }}
      />
      <MonthButton
        onClick={() => {
          setIsPickerOpen(true);
        }}
      >
        <span className="momentValue">{selectedMonthYear}</span>
        <IconChevronRight src={svgChevronRight} />
      </MonthButton>
    </div>
  );
};
HeaderPicker.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func,
};

const preventTouchMove = (event) => {
  event.preventDefault();
};

const SlideInDatePicker = ({ drawerOpen, setIsDatePickerOpen, onConfirm }) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [outputData, setOutputData] = useState(moment());
  const onClose = (resetSelect = true) => {
    setIsDatePickerOpen(false);
    if (resetSelect) {
      setSelectedDate(outputData);
    }
    document.body.removeEventListener('touchmove', preventTouchMove, false);
  };
  const onClickConfirm = () => {
    setOutputData(selectedDate);
    onClose(false);
    onConfirm(selectedDate);
  };
  const headerRender = () => {
    return '';
  };
  useEffect(() => {
    if (drawerOpen) {
      document.body.addEventListener('touchmove', preventTouchMove);
    } else {
      document.body.removeEventListener('touchmove', preventTouchMove, false);
    }
  }, [drawerOpen]);
  return (
    <>
      <StyledDrawer
        /**
         * iPad的LIFF只有540x576年份選擇器會有問題
         * height={466}
         */
        height={isCompactView() ? 410 : 466}
        open={drawerOpen}
        closable={false}
        placement={'bottom'}
        onClose={onClose}
      >
        <HeaderPicker date={selectedDate} onChange={setSelectedDate} />
        <StyledCalendar
          value={selectedDate}
          fullscreen={false}
          headerRender={headerRender}
          onChange={setSelectedDate}
          locale={calendarLocale}
          disabledDate={(date) => date.isAfter()}
        />
        {/* {`innerWidth: ${window.innerWidth}, innerHeight: ${window.innerHeight}`}
        <br />
        {`screenWidth: ${screen.width}, screenHeight: ${screen.height}`} */}
        <StandardButton
          type="primary"
          size="large"
          block
          onClick={onClickConfirm}
        >
          確認
        </StandardButton>
      </StyledDrawer>
    </>
  );
};

SlideInDatePicker.propTypes = {
  drawerOpen: PropTypes.bool,
  setIsDatePickerOpen: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default SlideInDatePicker;
