/**
 * 捲動式文字框
 */
import styled from 'styled-components';

import { PropTypes } from '@/utils/propTypes';

import Content from './Content';
import Title from './Title';

const TextView = styled.div`
  background: #f5f5f5;
  height: 180px;
  overflow: auto;
  padding: 16px 16px 16px 12px;
  border-radius: 5px;
  ol,
  ul {
    display: block;
    list-style-type: auto;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
  }
`;

const ScrollingTextView = ({ title, content }) => {
  return (
    <div>
      <TextView>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </TextView>
    </div>
  );
};

ScrollingTextView.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
export default ScrollingTextView;
